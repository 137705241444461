function initSmoothScroll() {
	// Smooth scrolling when clicking on a anchor link
	$('a').on('click', function (e) {
		if (this.hash && this.href.includes(window.location.href)) {
			e.preventDefault();

			var target = this.hash;
			var $target = $(target);
			if ($target.length) {
				if ($(window).width() > 1023) {
					var $scrollTop = $target.offset().top - 120;
				} else {
					var $scrollTop = $target.offset().top - 70;
				}

				$('html, body').stop().animate({
					'scrollTop': $scrollTop
				}, 900, 'swing');
			}
		}
	});

}

export default initSmoothScroll;
