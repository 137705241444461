import './main.scss';

import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;
import lozad from 'lozad';
import Rellax from 'rellax';
import KeenSlider from 'keen-slider';

import initSlideshow from './scripts/slideshow';
import initSmoothScroll from './scripts/smoothScroll';
import initTouch from './scripts/touch';
import initMenu from './scripts/menu';
import initCounter from './scripts/counter';
import initScrollHeader from './scripts/header';
import initAjaxFilters from './scripts/filter';
import displayLabel from './scripts/form';
import initLightBox from './scripts/lightbox';
import initSearch from './scripts/search';
import initLoadMore from './scripts/loadmore';
import initModal from './scripts/modal';
import initPlayer from './scripts/video';

var observer;

function createSlider(wrapper) {
	var loaded = [];
	var elements = wrapper.querySelectorAll(".slide");
	new KeenSlider(wrapper.querySelector(".js-slider"), {
		loop: true,
		created: function (instance) {
			wrapper
				.querySelector(".slideshow__controls__prev")
				.addEventListener("click", function () {
					instance.prev();
				});

			wrapper
				.querySelector(".slideshow__controls__next")
				.addEventListener("click", function () {
					instance.next();
				});
			updateClasses(instance);
		},
		slideChanged(instance) {
			updateClasses(instance);
		},
		afterChange: (s) => {
			var slideIdx = s.details().relativeSlide;
			loaded[slideIdx] = true;
			elements.forEach((element, idx) => {
				var src = element.querySelector('img').dataset.src;
				var img = element.querySelector('img');
				if (loaded[idx]) img.setAttribute("src", src);
			});
		},
	});

	function updateClasses(instance) {
		var slide = instance.details().relativeSlide;
		var arrowLeft = wrapper.querySelector(".slideshow__controls__prev");
		var arrowRight = wrapper.querySelector(".slideshow__controls__next");
		slide === 0 ?
			arrowLeft.classList.add("arrow--disabled") :
			arrowLeft.classList.remove("arrow--disabled");
		slide === instance.details().size - 1 ?
			arrowRight.classList.add("arrow--disabled") :
			arrowRight.classList.remove("arrow--disabled");
	}
}

window.addEventListener("load", function (event) {

	// $('.container-fluid').append('<span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span><span class="dev-column"></span>');

	initSlideshow();
	initSmoothScroll();
	initTouch();
	initMenu();
	initCounter();
	initScrollHeader('body', 50);
	initAjaxFilters();
	displayLabel();
	initLightBox();
	initSearch();
	initLoadMore();
	initModal();
	initPlayer();
	observer = lozad();
	observer.observe();

	var rellax = new Rellax('.rellax');
});
