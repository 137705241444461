import { tns } from 'tiny-slider';

function initSlideshow() {
	if ($('.js-slideshow-slider').length > 0) {
		$('.js-slideshow-slider').each(function (index, value) {
			var slider = tns({
				container: this,
				slideBy: 1,
				items: 1,
				speed: 800,
				loop: true,
				mode: 'gallery',
				autoplay: true,
				nav: true,
				navContainer: '.hero .nav-container',
				controls: false,
			});
		});
	}

	if ($('.js-slideshow-testimonials').length > 0) {
		$('.js-slideshow-testimonials').each(function (index, value) {

			$navContainer = $(this).parents('.testimonials__slider').find('.nav-container')[0];
			$prevButton = $(this).parents('.testimonials__slider').find('.slideshow__controls__prev')[0];
			$nextButton = $(this).parents('.testimonials__slider').find('.slideshow__controls__next')[0];

			var slider = tns({
				container: this,
				slideBy: 1,
				items: 1,
				mode: 'gallery',
				nav: true,
				navContainer: $navContainer,
				prevButton: $prevButton,
				nextButton: $nextButton,
			});
		});
	}

	if ($('.js-gallery').length > 0) {
		$('.js-gallery').each(function (index, value) {

			$navContainer = $(this).parents('.images__slider').find('.nav-container')[0];
			$prevButton = $(this).parents('.images__slider').find('.slideshow__controls__prev')[0];
			$nextButton = $(this).parents('.images__slider').find('.slideshow__controls__next')[0];

			var slider = tns({
				container: this,
				slideBy: 1,
				items: 1,
				gutter: 30,
				speed: 800,
				loop: true,
				nav: true,
				navContainer: $navContainer,
				prevButton: $prevButton,
				nextButton: $nextButton,
			});
		});
	}

	if ($('.js-slideshow-pictos').length > 0) {
		$('.js-slideshow-pictos').each(function (index, value) {

			$prevButton = $(this).parents('.pictos__slider').find('.slideshow__controls__prev')[0];
			$nextButton = $(this).parents('.pictos__slider').find('.slideshow__controls__next')[0];

			var slider = tns({
				container: this,
				slideBy: 1,
				items: 1,
				speed: 800,
				loop: false,
				nav: false,
				mouseDrag: true,
				prevButton: $prevButton,
				nextButton: $nextButton,
				gutter: 20,
				responsive: {
					540: {
						items: 2
					},
					900: {
						items: 3,
						gutter: 30,
					},
					1024: {
						items: 4,
					}
				}
			});
		});
	}
}
export default initSlideshow;
